import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {
            is_logged_in: false,
            data: null,
            permisos: null
        }
    },
    mutations: {
        setUserData(state, data) {
            state.user.data = data;
        },
        setIsLoggedIn(state, data) {
            state.user.is_logged_in = data;
        },
        setPermisos(state, data) {
            state.user.permisos = data;
        },
    },
    actions: {},
    modules: {}
})

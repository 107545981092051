<template>
  <nav class="d-flex">
    <ul class="pagination justify-content-left pagination-sm" v-show="pagesNumber.length > 1">
      <li class="page-item">
        <a class="page-link" href="#" v-on:click="first" aria-label="First" v-if="items.current_page>1">
          <span aria-hidden="true">|«</span>
        </a>
      </li>
      <li class="page-item" v-show="items.current_page > 1">
        <a class="page-link" href="#" v-on:click="previous(items.current_page)" aria-label="Previous">
          <span aria-hidden="true">«</span>
        </a>
      </li>
      <li class="page-item" v-for="page in pagesNumber"
          :class="[ page == isActived ? 'active' : '']">
        <a class="page-link" href="#" v-on:click="cambiarPagina(page)">{{ page }}</a>
      </li>
      <li class="page-item" v-show="items.current_page < items.last_page">
        <a class="page-link" href="#" v-on:click="next(items.current_page)" aria-label="Next">
          <span aria-hidden="true">»</span>
        </a>
      </li>
      <li class="page-item" v-show="items.current_page < items.last_page">
        <a class="page-link" href="#" v-on:click="last" aria-label="Last">
          <span aria-hidden="true">»|</span>
        </a>
      </li>
    </ul>
    <div class="d-flex flex-row flex-grow-1 justify-content-end align-items-center">
      <span><b>{{ total + (total > 1 ? ' Resultados' : ' Resultado') }}</b></span>
    </div>

  </nav>
</template>

<script>
import PageSaver from "../../code/PageSaver";

export default {
  props: {
    url: {
      type: String,
      default: null,
    },
    saverEnabled: {
      type: Boolean,
      default: false,
    }
  },
  data: function () {
    return {
      offset: 4,
      pagesArray: [],
      items: [],
      filtros: {},
      page: 1,
      total: 0
    }
  },
  async mounted() {
    if (this.$parent.$options.name && this.saverEnabled) {
      await PageSaver.init(this.$parent.$options.name, this.$route.query);
      this.page = PageSaver.restore();
    }
  },
  computed: {
    isActived: function () {
      return this.items.current_page
    },
    pagesNumber: function () {
      if (!this.items.to) {
        return [];
      }

      let from = this.items.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      let to = from + (this.offset * 2);
      if (to >= this.items.last_page) {
        to = this.items.last_page;
      }
      this.pagesArray = [];
      while (from <= to) {
        this.pagesArray.push(from);
        from++;
      }
      return this.pagesArray;
    }
  },
  methods: {
    cambiarPagina(page) {
      this.page = page;
      this.consultar(this.filtros);
    },
    previous(page) {
      this.page = page - 1;
      this.consultar(this.filtros);
    },
    next(page) {
      this.page = page + 1;
      this.consultar(this.filtros);
    },
    last() {
      this.page = this.items.last_page;
      this.consultar(this.filtros);
    },
    first() {
      this.page = 1;
      this.consultar(this.filtros);
    },
    consultar(filtros) {
      if (this.saverEnabled) {
        PageSaver.save(this.page);
      }

      this.filtros = filtros;
      this.buscando = true;
      axios.get(this.url, {
        params: {
          page: this.page,
          campos: this.filtros
        }
      }).then(response => {
        this.items = response.data.data.items;
        this.total = response.data.data.total;
        if (this.items.current_page > this.items.last_page) {
          console.debug("Current page is higher than last page, resetting to page 1");
          this.first();
        } else {
          this.$emit('loaded', this.items, response.data.data);
        }
      });
    }
  }
}
</script>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import permisos from "./permisos";
import {DateTime} from 'luxon';

import "./styles/app.scss";

import MainMenu from './components/home/MainMenu'
import FlashAlert from "./components/utils/FlashAlert";
import MainNotifications from "./components/home/MainNotifications";

window.axios = require("axios").default;
window.jQuery = window.$ = require('jquery');

Vue.config.productionTip = false

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL

require('@popperjs/core')
window.bootstrap = require('bootstrap');
require('bootstrap-select');
$.fn.selectpicker.Constructor.BootstrapVersion = '5';
require('bootstrap-datepicker');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.es.min');

axios.get('/sanctum/csrf-cookie')

Vue.component('main-menu', MainMenu);
Vue.component('flash-alert', FlashAlert);
Vue.component('main-notifications', MainNotifications);

Vue.prototype.$permisos = permisos;
Vue.prototype.$refreshSelectPicker = () => {
    Vue.nextTick(() => {
        $('.selectpicker').selectpicker('refresh');
    });
};

import FloatingVue from 'floating-vue'
Vue.use(FloatingVue)

import ToggleButton from 'vue-js-toggle-button';
Vue.use(ToggleButton);

import PortalVue from "portal-vue";
Vue.use(PortalVue);

Vue.filter('formatToHours', (value) => {
    let dt = DateTime.fromISO(value);
    return dt.toLocaleString(DateTime.TIME_24_SIMPLE);
});

Vue.filter('formatDateLocale', (value, format = DateTime.DATETIME_FULL) => {
    let dt = DateTime.fromISO(value);
    return dt.toLocaleString(format);
});

Vue.filter('formatMoneda', (value, format = {style: "currency", currency: "MXN"}, locale = "es-MX") => {
    return value.toLocaleString(locale, format);
});

window.MapsLoader = require("@googlemaps/js-api-loader").Loader;

// import CKEditor from '@ckeditor/ckeditor5-vue2';
// Vue.use(CKEditor);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

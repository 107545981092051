const STOCK_MINIMO = 'stock_minimo';
const EVENTO = 'evento';
const VENCIMIENTO_GARANTIA = 'vencimiento_garantia';
const VIGENCIA_COTIZACION = 'vigencia_cotizacion';
const TRASLADO_PENDIENTE = 'traslado_pendiente';
const TRASLADO_COMPLETADO = 'traslado_completado';
const MANTENIMIENTO_PENDIENTE = 'mantenimiento_pendiente';

export default {
    STOCK_MINIMO,
    EVENTO,
    VENCIMIENTO_GARANTIA,
    VIGENCIA_COTIZACION,
    TRASLADO_PENDIENTE,
    TRASLADO_COMPLETADO,
    MANTENIMIENTO_PENDIENTE,
}
import router from '../router'

export default {
    key_prefix: '',
    query: null,
    needs_reset: false,
    enabled: false,

    async init(key_prefix, query) {
        this.needs_reset = false;
        this.key_prefix = key_prefix;
        this.query = query;
        if (this.key_prefix) {
            this.enabled = true;
        }

        if (query.hasOwnProperty('reset')) {
            console.debug('PageSaver needs reset.')
            this.needs_reset = true;

            // let q = Object.assign({}, query);
            // delete q.reset;
            // await router.replace({query: q});
        }
    },
    save(page) {
        if (this.enabled) {
            console.debug('save page ', this.key_prefix, page);
            localStorage.setItem(this.key_prefix + '.page', JSON.stringify(page));
        }
    },
    restore() {
        if (!this.enabled) {
            console.debug('PageSaver not enabled');
            return 1;
        }

        if (this.needs_reset) {
            console.debug('PageSaver Reset to default state');
            return 1;
        } else {
            let recovered = JSON.parse(localStorage.getItem(this.key_prefix + '.page'));
            console.debug('recovered page', recovered);
            if (recovered) {
                return recovered;
            } else {
                return 1;
            }
        }
    }
}
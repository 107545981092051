<template>
  <div class="bg-primary"
       :class="{'menu-collapsed': menuCollapsed, 'menu': !menuCollapsed}">
    <div class="text-center small text-light px-1 position-absolute" v-if="!menuCollapsed">
      <router-link to="/changelog" class="text-light">
        <small>v{{ version }}</small>
      </router-link>
    </div>
    <div v-if="!menuCollapsed" class="p-2 d-flex flex-column h-100">
      <router-link to="/">
        <img src="../../assets/lf_logo.png" class="w-100">
      </router-link>
      <div class="flex-grow-1 my-3 text-center overflow-auto">
        <div class="d-flex flex-row mb-3">
          <router-link to="/cotizaciones/create?movimiento=cotizacion"
                       v-if="$permisos.can('cotizaciones.crear')"
                       class="btn btn-outline-light btn-sm d-block w-100 mb-1 me-1">
            Cotización
          </router-link>
          <router-link to="/ventas/create?movimiento=venta" v-if="$permisos.can('cotizaciones.crear')"
                       class="btn btn-outline-light btn-sm d-block w-100 mb-1">
            Venta
          </router-link>
        </div>

        <router-link to="/catalogos"
                     :class="{'selected': selected === 'catalogos'}"
                     class="menu-option text-decoration-none d-block w-100 mb-1">
          Catálogos
        </router-link>

        <hr>

        <router-link to="/existencias?reset" v-if="$permisos.can('existencias.menu')"
                     :class="{'selected': selected === 'existencias'}"
                     class="menu-option text-decoration-none d-block w-100 mb-1">
          Existencias
        </router-link>

        <hr>

        <router-link to="/cotizaciones?reset" v-if="$permisos.can('cotizaciones.menu')"
                     :class="{'selected': selected === 'cotizaciones'}"
                     class="menu-option text-decoration-none d-block w-100 mb-1">
          Cotizaciones
        </router-link>
        <router-link to="/ventas?reset" v-if="$permisos.can('ventas.menu')"
                     :class="{'selected': selected === 'ventas'}"
                     class="menu-option text-decoration-none d-block w-100 mb-1">
          Ventas
        </router-link>

        <hr>

        <router-link to="/facturas_globales" v-if="$permisos.can('facturas.menu')"
                     :class="{'selected': selected === 'facturas_globales'}"
                     class="menu-option text-decoration-none d-block w-100 mb-1">
          Facturas globales
        </router-link>

        <hr>

        <router-link to="/polizas?reset" v-if="$permisos.can('polizas.menu')"
                     :class="{'selected': selected === 'polizas'}"
                     class="menu-option text-decoration-none d-block w-100 mb-1">
          Pólizas
        </router-link>

        <hr>

        <router-link to="/depositos?reset" v-if="$permisos.can('depositos.menu')"
                     :class="{'selected': selected === 'depositos'}"
                     class="menu-option text-decoration-none d-block w-100 mb-1">
          Depósitos
        </router-link>

        <hr>

        <router-link to="/agenda?reset" v-if="$permisos.can('agenda.menu')"
                     :class="{'selected': selected === 'agenda'}"
                     class="menu-option text-decoration-none d-block w-100 mb-1">
          Agenda
        </router-link>

        <hr>

        <router-link to="/cajas/movimientos?reset" v-if="$permisos.can('movimientos_caja.menu')"
                     :class="{'selected': selected === 'movimientos-caja'}"
                     class="menu-option text-decoration-none d-block w-100 mb-1">
          Movimientos de caja
        </router-link>
        <router-link to="/cierres?reset" v-if="$permisos.can('aperturas_cierres.menu')"
                     :class="{'selected': selected === 'cierres'}"
                     class="menu-option text-decoration-none d-block w-100 mb-1">
          Aperturas y Cierres
        </router-link>
      </div>
      <div class="my-2">
        <div class="d-flex flex-row justify-content-end mb-2">
          <button class="btn btn-outline-dark" @click="toggleMenuCollapse">
            <i class="fa fa-chevron-left"></i>
          </button>
        </div>

        <router-link to="/mi_cuenta" class="btn btn-sm btn-outline-dark w-100 mb-2"
                     v-tooltip="'Mi cuenta'">
          <i class="fa fa-user-circle me-1"></i>
          <span>{{ $store.state.user.data.full_name }}</span>
        </router-link>

        <button @click="logout" class="btn btn-sm btn-outline-dark w-100">
          <i class="fa fa-door-open"></i> Cerrar sesión
        </button>
      </div>
      <div class="p-2">
        <img src="../../assets/dev_by_white.png" class="w-100">
      </div>
    </div>
    <div v-else class="d-flex flex-column h-100">
      <div class="text-center small text-light px-1">
        <router-link to="/changelog" class="text-light">
          <small>v{{ version }}</small>
        </router-link>
      </div>
      <router-link to="/">
        <img src="../../assets/lf_logo.png" class="w-100">
      </router-link>
      <div class="p-2 flex-grow-1 my-3 text-center overflow-auto">
        <div class="d-flex flex-column mb-3">
          <router-link to="/cotizaciones/create?movimiento=cotizacion"
                       v-tooltip.hover.right="'Crear cotización'"
                       v-if="$permisos.can('cotizaciones.crear')"
                       class="btn btn-outline-light btn-sm d-block w-100 mb-1 me-1">
            <i class="fa fa-file-invoice-dollar"></i>
          </router-link>
          <router-link to="/ventas/create?movimiento=venta"
                       v-tooltip.hover.right="'Hacer venta'"
                       v-if="$permisos.can('cotizaciones.crear')"
                       class="btn btn-outline-light btn-sm d-block w-100 mb-1">
            <i class="fa fa-receipt"></i>
          </router-link>
        </div>

        <router-link to="/catalogos"
                     v-tooltip.hover.right="'Catálogos'"
                     :class="{'selected': selected === 'catalogos'}"
                     class="menu-option text-decoration-none d-block w-100 mb-1 py-1">
          <i class="fa fas fa-book"></i>
        </router-link>

        <hr>

        <router-link to="/existencias?reset"
                     v-tooltip.hover.right="'Existencias'"
                     v-if="$permisos.can('existencias.menu')"
                     :class="{'selected': selected === 'existencias'}"
                     class="menu-option text-decoration-none d-block w-100 mb-1 py-1">
          <i class="fa fa-warehouse-alt"></i>
        </router-link>

        <hr>

        <router-link to="/cotizaciones?reset"
                     v-tooltip.hover.right="'Cotizaciones'"
                     v-if="$permisos.can('cotizaciones.menu')"
                     :class="{'selected': selected === 'cotizaciones'}"
                     class="menu-option text-decoration-none d-block w-100 mb-1 py-1">
          <i class="fa fa-file-invoice-dollar"></i>
        </router-link>
        <router-link to="/ventas?reset"
                     v-tooltip.hover.right="'Ventas'"
                     v-if="$permisos.can('ventas.menu')"
                     :class="{'selected': selected === 'ventas'}"
                     class="menu-option text-decoration-none d-block w-100 mb-1 py-1">
          <i class="fa fa-receipt"></i>
        </router-link>

        <hr>

        <router-link to="/facturas_globales"
                     v-tooltip.hover.right="'Facturas globales'"
                     v-if="$permisos.can('facturas.menu')"
                     :class="{'selected': selected === 'facturas_globales'}"
                     class="menu-option text-decoration-none d-block w-100 mb-1 py-1">
          <i class="fa fa-file-code"></i>
        </router-link>

        <hr>

        <router-link to="/polizas?reset"
                     v-tooltip.hover.right="'Pólizas'"
                     v-if="$permisos.can('polizas.menu')"
                     :class="{'selected': selected === 'polizas'}"
                     class="menu-option text-decoration-none d-block w-100 mb-1 py-1">
          <i class="fa fa-line-columns"></i>
        </router-link>

        <hr>

        <router-link to="/depositos?reset"
                     v-tooltip.hover.right="'Depósitos'"
                     v-if="$permisos.can('depositos.menu')"
                     :class="{'selected': selected === 'depositos'}"
                     class="menu-option text-decoration-none d-block w-100 mb-1 py-1">
          <i class="fa fa-money-check-alt"></i>
        </router-link>

        <hr>

        <router-link to="/agenda?reset"
                     v-tooltip.hover.right="'Agenda'"
                     v-if="$permisos.can('agenda.menu')"
                     :class="{'selected': selected === 'agenda'}"
                     class="menu-option text-decoration-none d-block w-100 mb-1 py-1">
          <i class="fa fa-calendar-alt"></i>
        </router-link>

        <hr>

        <router-link to="/cajas/movimientos?reset"
                     v-tooltip.hover.right="'Movimientos de Cajas'"
                     v-if="$permisos.can('movimientos_caja.menu')"
                     :class="{'selected': selected === 'movimientos-caja'}"
                     class="menu-option text-decoration-none d-block w-100 mb-1 py-1">
          <i class="fa fa-exchange"></i>
        </router-link>
        <router-link to="/cierres?reset"
                     v-tooltip.hover.right="'Aperturas y cierres'"
                     v-if="$permisos.can('aperturas_cierres.menu')"
                     :class="{'selected': selected === 'cierres'}"
                     class="menu-option text-decoration-none d-block w-100 mb-1 py-1">
          <i class="fa fa-cash-register"></i>
        </router-link>
      </div>
      <div class="p-2 my-2">
        <div class="d-flex flex-row justify-content-end mb-2">
          <button class="btn btn-outline-dark" @click="toggleMenuCollapse">
            <i class="fa fa-chevron-right"></i>
          </button>
        </div>

        <router-link to="/mi_cuenta" class="btn btn-sm btn-outline-dark w-100 mb-2"
                     v-tooltip="'Mi cuenta'">
          <i class="fa fa-user-circle me-1"></i>
        </router-link>

        <button @click="logout" class="btn btn-sm btn-outline-dark w-100">
          <i class="fa fa-door-open"></i>
        </button>
      </div>
      <!--      <div class="p-2">-->
      <!--        <img src="../../assets/dev_by_white.png" class="w-100">-->
      <!--      </div>-->
    </div>
  </div>
</template>
<script>
export default {
  name: 'Menu',
  watch: {
    $route(to, from) {
      this.selected = to.meta.menu;
    }
  },
  data() {
    return {
      selected: null,
      menuCollapsed: false,
      version: process.env.VUE_APP_VERSION
    }
  },
  mounted() {
    this.menuCollapsed = localStorage.getItem('collapse.menu') === 'true';
  },
  methods: {
    logout() {
      axios.post('/logout')
          .then(() => {
            this.$store.commit('setUserData', null);
            this.$store.commit('setIsLoggedIn', false);
            this.$router.replace('/login');
          });
    },
    toggleMenuCollapse() {
      this.menuCollapsed = !this.menuCollapsed;
      localStorage.setItem('collapse.menu', this.menuCollapsed);
    }
  }
}
</script>
<style scoped>

hr {
  margin: 3px;
}

.menu {
  min-width: 200px;
  max-width: 200px;
}

.menu-collapsed {
  min-width: 50px;
  max-width: 50px;
}

</style>
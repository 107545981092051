const {DateTime} = require("luxon");

export default {
    buildData(old, separador, newData) {
        var cadena = "";
        if (old === null) {
            cadena = newData;
        } else {
            cadena = old + ((newData !== "" || newData.length > 0) ? separador + ' ' : '') + newData;
        }
        return cadena;
    },
    getDireccion(data) {
        var direccion = "";
        direccion = this.buildData(null, ',', this.validateValor(data.calle));
        direccion = this.buildData(direccion, ',', (this.validateValor(data.num_exterior).length > 0 ? 'No. Ext.' + data.num_exterior : ""));
        direccion = this.buildData(direccion, ',', (this.validateValor(data.num_interior).length > 0 ? 'No. Int.' + data.num_interior : ""));
        direccion = this.buildData(direccion, ',', this.validateValor(data.colonia));
        direccion = this.buildData(direccion, ',', this.validateValor(data.codigo_postal));
        direccion = this.buildData(direccion, ',', this.validateValor(data.ciudad));
        direccion = this.buildData(direccion, ',', this.validateValor(data.estado_name));
        direccion = direccion !== null ? direccion : 'Sin dirección';

        return direccion;

    },
    validateValor(value) {
        return value === 'null' || value === undefined || value === null || value === 'undefined' || !value.length ? '' : value;
    },
    formatFecha(value, format = "dd/MM/yyyy") {
        return DateTime.fromISO(value).toFormat(format);
    },
    formatText(cadena) {
        let primerCaracter = cadena.charAt(0).toUpperCase();
        let restoDeLaCadena = cadena.substring(1, cadena.length);
        return primerCaracter.concat(restoDeLaCadena);
    },
    formatTextMayus(cadena) {
        return cadena ? cadena.toUpperCase() : null;
    },
    formatNumber(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
        return value.toLocaleString('en-MX', {minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: maximumFractionDigits});
    },
    formatMoneda(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
        return value.toLocaleString("es-MX", {style: "currency", currency: "MXN", minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: maximumFractionDigits});
    },
    formatMonedaLocal(value, replace = ',', decimales = 2) {
        var parts = value.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, replace);
        if (parts[1]) {
            parts[1] = parts[1].substring(0, decimales);
        }
        return parts.join(".");
    },
    replaceChartText(cadena, charSearch, charReplace) {
        let regex = new RegExp(charSearch, "gi");
        if (cadena == null) {
            return '';
        }
        return cadena.replace(regex, charReplace);
    },
    isNumber(event) {
        event = (event) ? event : window.event;
        let charCode = (event.which) ? event.which : event.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            event.preventDefault();
        } else {
            return true;
        }
    },
}
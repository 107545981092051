import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import store from '../store'

Vue.use(VueRouter)

const bCatalogos = {
    url: '/catalogos',
    texto: 'Catálogos'
};
const bClientes = {
    url: '/clientes',
    texto: 'Clientes'
};

const bUsuarios = {
    url: '/usuarios',
    texto: 'Usuarios'
};

const bSucursales = {
    url: '/sucursales',
    texto: 'Sucursales'
};

const bLineas = {
    url: '/lineas',
    texto: 'Lineas'
};

const bFormasPago = {
    url: '/formas_pago',
    texto: 'Formas de pago'
};

const bImpuestos = {
    url: '/impuestos',
    texto: 'Impuestos'
};

const bListasPrecio = {
    url: '/listas_precios',
    texto: 'Listas de precio'
};

const bProductos = {
    url: '/productos',
    texto: 'Productos'
};

const bKits = {
    url: '/kits',
    texto: 'Kits'
};

const bExistencias = {
    url: '/existencias',
    texto: 'Existencias'
};

const bCajas = {
    url: '/cajas',
    texto: 'Cajas'
};

const bMovimientosCaja =
{
    url: '/cajas/movimientos',
    texto: 'Movimientos de caja',
};

const bCierres =
{
    url: '/cierres',
    texto: 'Aperturas y cierres',
};

const bTiposPoliza = {
    url: '/tipos_poliza',
    texto: 'Tipos de póliza'
};

const bPolizas = {
    url: '/polizas',
    texto: 'Pólizas'
};

const bCuentasBancarias = {
    url: '/cuentas_bancarias',
    texto: 'Cuentas bancarias'
};

const bDepositos = {
    url: '/depositos',
    texto: 'Depósitos'
};

const bPlantillas = {
    url: '/plantillas',
    texto: 'Plantillas'
};
const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            guest: true
        }
    },
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/changelog',
        name: 'Changelog',
        component: () => import(/* webpackChunkName: "changelog" */ '../views/changelog/Changelog'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/usuarios',
        name: 'ListaUsuarios',
        component: () => import(/* webpackChunkName: "usuarios" */ '../views/usuarios/Lista'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bUsuarios,
            ]
        }
    },
    {
        path: '/usuarios/create/:id?',
        name: 'CrearEditarUsuario',
        component: () => import(/* webpackChunkName: "usuarios" */ '../views/usuarios/CrearEditar'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bUsuarios,
                {
                    url: '',
                    texto: 'Registro de usuario',
                }
            ]
        }
    },
    {
        path: '/sucursales',
        name: 'ListaSucursales',
        component: () => import(/* webpackChunkName: "sucursales" */ '../views/sucursales/Lista'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bSucursales,
            ]
        }
    },
    {
        path: '/sucursales/create/:id?',
        name: 'CrearEditarSucursal',
        component: () => import(/* webpackChunkName: "sucursales" */ '../views/sucursales/CrearEditar'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bSucursales,
                {
                    url: '',
                    texto: 'Registro de sucursal',
                }
            ]
        }
    },
    {
        path: '/lineas',
        name: 'ListaLineas',
        component: () => import(/* webpackChunkName: "lineas" */ '../views/categorias/Lista'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bLineas,
            ]
        }
    },
    {
        path: '/lineas/create/:id?',
        name: 'CrearEditarLinea',
        component: () => import(/* webpackChunkName: "lineas" */ '../views/categorias/CrearEditar'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bLineas,
                {
                    url: '',
                    texto: 'Registro de linea',
                }
            ]
        }
    },
    {
        path: '/formas_pago',
        name: 'ListaFormasPago',
        component: () => import(/* webpackChunkName: "lineas" */ '../views/formas_pago/Lista'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bFormasPago,
            ]
        }
    },
    {
        path: '/formas_pago/create/:id?',
        name: 'CrearEditarFormaPago',
        component: () => import(/* webpackChunkName: "formas_pago" */ '../views/formas_pago/CrearEditar'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bFormasPago,
                {
                    url: '',
                    texto: 'Registro de forma de pago',
                }
            ]
        }
    },
    {
        path: '/catalogos',
        name: 'MainCatalogos',
        component: () => import(/* webpackChunkName: "catalogos" */ '../components/catalogos/MainCatalogos'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
            ]
        }
    },
    {
        path: '/clientes',
        name: 'ListaClientes',
        component: () => import(/* webpackChunkName: "clientes" */ '../views/clientes/Lista'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bClientes
            ]
        }
    },
    {
        path: '/clientes/create/:id?',
        name: 'CrearEditarCliente',
        component: () => import(/* webpackChunkName: "clientes" */ '../views/clientes/CrearEditar'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bClientes,
                {
                    url: '',
                    texto: 'Registro de cliente',
                }
            ]
        }
    },
    {
        path: '/impuestos',
        name: 'ListaImpuestos',
        component: () => import(/* webpackChunkName: "impuestos" */ '../views/impuestos/Lista'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bImpuestos,
            ]
        }
    },
    {
        path: '/impuestos/create/:id?',
        name: 'CrearEditarImpuesto',
        component: () => import(/* webpackChunkName: "impuestos" */ '../views/impuestos/CrearEditar'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bImpuestos,
                {
                    url: '',
                    texto: 'Registro de impuesto',
                }
            ]
        }
    },
    {
        path: '/listas_precios',
        name: 'ListaListasPrecios',
        component: () => import(/* webpackChunkName: "listas_precios" */ '../views/listas_precios/Lista'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bListasPrecio,
            ]
        }
    },
    {
        path: '/listas_precios/create/:id?',
        name: 'CrearEditarListaPrecio',
        component: () => import(/* webpackChunkName: "listas_precios" */ '../views/listas_precios/CrearEditar'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bListasPrecio,
                {
                    url: '',
                    texto: 'Registro de lista de precio',
                }
            ]
        }
    },
    {
        path: '/productos',
        name: 'ListaProductos',
        component: () => import(/* webpackChunkName: "productos" */ '../views/productos/Lista'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bProductos,
            ]
        }
    },
    {
        path: '/productos/create/:id?',
        name: 'CrearEditarProductos',
        component: () => import(/* webpackChunkName: "productos" */ '../views/productos/CrearEditar'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bProductos,
                {
                    url: '',
                    texto: 'Registro de producto',
                }
            ]
        }
    },
    {
        path: '/listas_precios/asociar_precio',
        name: 'AsociarPrecio',
        component: () => import(/* webpackChunkName: "listas_precios" */ '../views/listas_precios/AsociarPrecio'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bListasPrecio,
                {
                    url: '',
                    texto: 'Asociar precios',
                }
            ]
        }
    },
    {
        path: '/listas_precios/asociar_precio/:id?',
        name: 'AsociarPrecioSpecific',
        component: () => import(/* webpackChunkName: "listas_precios" */ '../views/listas_precios/AsociarPrecio'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bListasPrecio,
                {
                    url: '',
                    texto: 'Asociar precios',
                }
            ]
        }
    },
    {
        path: '/kits',
        name: 'ListaKits',
        component: () => import(/* webpackChunkName: "kits" */ '../views/kits/Lista'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bKits,
            ]
        }
    },
    {
        path: '/kits/create/:id?',
        name: 'CrearEditarKits',
        component: () => import(/* webpackChunkName: "kits" */ '../views/kits/CrearEditar'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bKits,
                {
                    url: '',
                    texto: 'Registro de kit',
                }
            ]
        }
    },
    {
        path: '/existencias',
        name: 'ListaExistencias',
        component: () => import(/* webpackChunkName: "existencias" */ '../views/existencias/Lista'),
        meta: {
            requiresAuth: true,
            menu: 'existencias',
            breadcrumbs: [
                bExistencias
            ]
        }
    },
    {
        path: '/kardex/:producto_id?',
        name: 'ListaKardex',
        props: route => ({
            producto_id: route.params.producto_id,
            almacen_id: route.query.almacen_id
        }),
        component: () => import(/* webpackChunkName: "existencias" */ '../views/existencias/Kardex'),
        meta: {
            requiresAuth: true,
            menu: 'existencias',
            breadcrumbs: [
                bExistencias,
                {
                    url: '',
                    texto: 'Kardex',
                }
            ]

        }
    },
    {
        path: '/existencias/movimientos',
        name: 'CrearMovimientos',
        component: () => import(/* webpackChunkName: "existencias" */ '../views/existencias/EntradasSalidas'),
        meta: {
            requiresAuth: true,
            menu: 'existencias',
            breadcrumbs: [
                bExistencias,
                {
                    url: '',
                    texto: 'Movimientos de inventario',
                }
            ]
        }
    },
    {
        path: '/cajas',
        name: 'ListaCajas',
        component: () => import(/* webpackChunkName: "cajas" */ '../views/cajas/Lista'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bCajas,
            ]
        }
    },
    {
        path: '/cajas/create/:id?',
        name: 'CrearEditarCaja',
        component: () => import(/* webpackChunkName: "cajas" */ '../views/cajas/CrearEditar'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bCajas,
                {
                    url: '',
                    texto: 'Registro de caja',
                }
            ]
        }
    },
    {
        path: '/cajas/movimientos',
        name: 'ListaMovimientosCaja',
        props: route => ({
            caja_id: route.query.caja_id
        }),
        component: () => import(/* webpackChunkName: "cajas" */ '../views/cajas/ListaMovimientos'),
        meta: {
            requiresAuth: true,
            menu: 'movimientos-caja',
            breadcrumbs: [
                bMovimientosCaja,
            ]
    }
    },
    {
        path: '/cajas/movimientos/create',
        name: 'CrearMovimientoCaja',
        props: route => ({
            caja_id: route.query.caja_id
        }),
        component: () => import(/* webpackChunkName: "cajas" */ '../views/cajas/CrearMovimientoCaja'),
        meta: {
            requiresAuth: true,
            menu: 'movimientos-caja',
            breadcrumbs: [
                bMovimientosCaja,
                {
                    url: '',
                    texto: 'Movimiento',
                }
            ]
        }
    },
    {
        path: '/cotizaciones/create/:id?',
        name: 'CrearEditarCotizacion',
        props: route => ({
            movimiento: route.query.movimiento,
        }),
        component: () => import(/* webpackChunkName: "cotizaciones" */ '../views/cotizaciones/CrearEditar'),
        meta: {
            requiresAuth: true,
            menu: 'cotizaciones'
        }
    },
    {
        path: '/ventas/create/:id?',
        name: 'CrearEditarVenta',
        props: route => ({
            movimiento: route.query.movimiento,
        }),
        component: () => import(/* webpackChunkName: "cotizaciones" */ '../views/cotizaciones/CrearEditar'),
        meta: {
            requiresAuth: true,
            menu: 'ventas'
        }
    },
    {
        path: '/agenda',
        name: 'Agenda',
        component: () => import(/* webpackChunkName: "eventos" */ '../views/eventos/Agenda'),
        meta: {
            requiresAuth: true,
            menu: 'agenda',
            breadcrumbs: [
                {
                    url: '',
                    texto: 'Agenda',
                },
            ]
        }
    },
    {
        path: '/cajas/movimientos/apertura',
        name: 'CrearAperturaCaja',
        component: () => import(/* webpackChunkName: "cajas" */ '../views/cajas/CrearApertura'),
        meta: {
            requiresAuth: true,
            menu: 'movimientos-caja',
            breadcrumbs: [
            bCierres,
                {
                    url: '',
                    texto: 'Apertura de caja',
                }
            ]
        }
    },
    {
        path: '/cotizaciones',
        name: 'ListaCotizaciones',
        component: () => import(/* webpackChunkName: "cotizaciones" */ '../views/cotizaciones/Lista'),
        meta: {
            requiresAuth: true,
            menu: 'cotizaciones'
        }
    },
    {
        path: '/cotizaciones/show/:id?',
        name: 'VerCotizacion',
        component: () => import(/* webpackChunkName: "cotizaciones" */ '../views/cotizaciones/ShowCotizacion'),
        meta: {
            requiresAuth: true,
            menu: 'cotizaciones',
            breadcrumbs: [

            ]
        }
    },
    {
        path: '/ventas/show/:id?',
        name: 'VerVenta',
        component: () => import(/* webpackChunkName: "cotizaciones" */ '../views/cotizaciones/ShowCotizacion'),
        meta: {
            requiresAuth: true,
            menu: 'ventas',
            breadcrumbs: [

            ]
        }
    },
    {
        path: '/ventas',
        name: 'ListaVentas',
        component: () => import(/* webpackChunkName: "ventas" */ '../views/cotizaciones/ListaVentas'),
        meta: {
            requiresAuth: true,
            menu: 'ventas'
        }
    },
    {
        path: '/cajas/movimientos/cierre',
        name: 'CrearCierreCaja',
        component: () => import(/* webpackChunkName: "cajas" */ '../views/cajas/CrearCierre'),
        meta: {
            requiresAuth: true,
            menu: 'cierres',
            breadcrumbs: [
                bCierres,
                {
                    url: '',
                    texto: 'Cierre de caja',
                }
            ]
        }
    },
    {
        path: '/cierres',
        name: 'ListaCierres',
        component: () => import(/* webpackChunkName: "cierres" */ '../views/aperturas_cierres/Lista'),
        meta: {
            requiresAuth: true,
            menu: 'cierres',
            breadcrumbs: [
                bCierres,
            ]
    }
    },
    {
        path: '/clientes/show/:id?',
        name: 'ShowCliente',
        component: () => import(/* webpackChunkName: "clientes" */ '../views/clientes/ShowCliente'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bClientes,
                {
                    url: '/clientes/show/{cliente_id}',
                    texto: 'Ver cliente'
                }
            ]
        }
        },
        {
            path: '/mi_cuenta',
            name: 'MiCuenta',
            component: () => import(/* webpackChunkName: "cuenta" */ '../views/cuenta/Index'),
            meta: {
                requiresAuth: true,
                menu: 'mi_cuenta',
                breadcrumbs: [
                    {
                        url: '/mi_cuenta',
                        texto: 'Mi cuenta'
                    }
                ]
            }
        },
    {
        path: '/facturas_globales',
        name: 'ListaFacturasGlobales',
        component: () => import(/* webpackChunkName: "facturas" */ '../views/facturas/Lista'),
        meta: {
            requiresAuth: true,
            menu: 'facturas_globales',
            breadcrumbs: [
                {
                    url: '',
                    texto: 'Facturas globales'
                }
            ],
        }
    },
    {
        path: '/facturas_globales/create/:id?',
        name: 'CrearEditarFacturaGlobal',
        component: () => import(/* webpackChunkName: "facturas" */ '../views/facturas/CrearEditar'),
        meta: {
            requiresAuth: true,
            menu: 'facturas_globales',
            breadcrumbs: [
                {
                    url: '/facturas_globales',
                    texto: 'Facturas globales'
                },
                {
                    url: '',
                    texto: 'Registro de factura global'
                }
            ],
        }
    },
    {
        path: '/tipos_poliza',
        name: 'ListaTiposPoliza',
        component: () => import(/* webpackChunkName: "polizas" */ '../views/polizas/ListaTipos'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bTiposPoliza,
            ]
        }
    },
    {
        path: '/tipos_poliza/create/:id?',
        name: 'CrearEditarTipoPoliza',
        component: () => import(/* webpackChunkName: "polizas" */ '../views/polizas/CrearEditarTipoPoliza'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bTiposPoliza,
                {
                    url: '',
                    texto: 'Registro de tipo de póliza',
                }
            ]
        }
    },
    {
        path: '/polizas',
        name: 'ListaPolizas',
        component: () => import(/* webpackChunkName: "polizas" */ '../views/polizas/Lista'),
        meta: {
            requiresAuth: true,
                menu: 'polizas',
                breadcrumbs: [
                {
                    url: '',
                    texto: 'Pólizas'
                }
            ],
        }
    },
    {
        path: '/polizas/create/:id?',
        name: 'CrearEditarPoliza',
        component: () => import(/* webpackChunkName: "polizas" */ '../views/polizas/CrearEditar'),
        meta: {
            requiresAuth: true,
                menu: 'polizas',
                breadcrumbs: [
                bPolizas,
                {
                    url: '',
                    texto: 'Registro de póliza',
                }
            ]
        }
    },
    {
        path: '/cuentas_bancarias',
        name: 'ListaCuentasBancarias',
        component: () => import(/* webpackChunkName: "cuentas" */ '../views/cuentas_bancarias/Lista'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
            bCatalogos,
            bCuentasBancarias,
            ]
        }
    },
    {
        path: '/cuentas_bancarias/create/:id?',
            name: 'CrearEditarCuentaBancaria',
        component: () => import(/* webpackChunkName: "cuentas" */ '../views/cuentas_bancarias/CrearEditar'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bCuentasBancarias,
                {
                    url: '',
                    texto: 'Registro de cuenta bancaria',
                }
            ]
        }
    },
    {
        path: '/depositos',
        name: 'ListaDepositos',
        component: () => import(/* webpackChunkName: "depositos" */ '../views/depositos/Lista'),
        meta: {
            requiresAuth: true,
            menu: 'depositos',
            breadcrumbs: [
                bDepositos,
            ]
        }
    },
    {
        path: '/depositos/create/:id?',
        name: 'CrearEditarDepositos',
        component: () => import(/* webpackChunkName: "depositos" */ '../views/depositos/CrearEditar'),
        meta: {
            requiresAuth: true,
            menu: 'depositos',
            breadcrumbs: [
                bDepositos,
                {
                    url: '',
                    texto: 'Registro del depósito',
                }
        ]
    }
    },
    {
        path: '/plantillas',
        name: 'ListaPlantillas',
        component: () => import(/* webpackChunkName: "plantillas" */ '../views/plantillas/Lista'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bPlantillas,
            ]
        }
    },
    {
        path: '/plantillas/create/:id?',
        name: 'CrearEditarPlantilla',
        component: () => import(/* webpackChunkName: "plantillas" */ '../views/plantillas/CrearEditar'),
        meta: {
            requiresAuth: true,
            menu: 'catalogos',
            breadcrumbs: [
                bCatalogos,
                bPlantillas,
                {
                    url: '',
                    texto: 'Registro de plantilla',
                }
            ]
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    function tryLogin(onOk, onError) {
        axios.get('/api/user')
            .then(res => {
                store.commit('setUserData', res.data.data.user);
                store.commit('setIsLoggedIn', true);
                store.commit('setPermisos', res.data.data.permisos);
                onOk();
            })
            .catch(err => {
                onError();
            })
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.state.user.is_logged_in && store.state.user.data != null) {
            next();
        } else {
            tryLogin(() => {
                next()
            }, () => {
                next({
                    path: '/login',
                    params: {nextUrl: to.fullPath}
                });
            });
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (store.state.user.is_logged_in && store.state.user.data != null) {
            next({name: 'Home'})
        } else {
            tryLogin(() => {
                next({name: 'Home'});
            }, () => {
                next();
            });
        }
    } else {
        tryLogin(() => {
            next();
        }, () => {
            next({
                path: '/login',
                params: {nextUrl: to.fullPath}
            });
        });
    }
});

export default router

<template>
    <div class="d-none d-md-block" :class="{'big': big, 'small': !big}">
        <div v-if="big" class="d-flex flex-column h-100">
            <div class="mb-2">
                <div class="text-light text-center h3 m-0 flex-grow-1">
                    Notificaciones
                </div>
            </div>

            <div class="text-center text-light flex-grow-1 my-3" v-if="datas.length === 0">
                <div class="card border border-light bg-secondary text-light mb-1">
                    <div class="card-body p-1">
                        <div class="row">
                            <div class="col-sm-12">
                                <span><b>No hay notificaciones para mostrar</b></span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="flex-grow-1" style="overflow-y: auto; overflow-x: hidden;" v-else>
                <div class="card border border-light bg-secondary text-light mb-2"
                     v-for="data in datas">
                    <div class="card-body p-1 ">
                        <div class="row">
                            <div class="col-sm-12">
                                <span class="badge w-100"
                                      :class="{
                                        'bg-light': data.tipo !== TipoNotificacion.TRASLADO_COMPLETADO,
                                        'bg-success': data.tipo === TipoNotificacion.TRASLADO_COMPLETADO,
                                        'text-dark': data.tipo !== TipoNotificacion.TRASLADO_COMPLETADO,
                                        'text-light': data.tipo === TipoNotificacion.TRASLADO_COMPLETADO
                                        }">
                                    <b>{{ data.name_tipo }}</b>
                                </span>
                            </div>
                            <div class="col-sm-12">
                                <div v-if="data.tipo === TipoNotificacion.TRASLADO_PENDIENTE">
                                    <span class="badge bg-warning text-danger w-100" style="font-weight: 900;">IMPORTANTE</span>
                                </div>
                            </div>
                            <div class="col-sm-10">
                                <div :class="!data.more ? 'collapse-notificacion' : ''">
                                    <div>
                                        {{ data.text }}
                                        <button class="btn btn-outline-light btn-sm d-block w-100 m-2" @click="detalleVenta(data)"
                                                v-if="data.more && data.tipo === TipoNotificacion.MANTENIMIENTO_PENDIENTE">
                                            <i class="fa fa-money-bill-wave"></i> Venta
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <button class="btn btn-success float-end btn-sm" @click="marcarVisto(data)">
                                    <i v-if="!saving" class="fa fa-check"></i>
                                    <i v-else class="fa fa-spinner fa-spin"></i>
                                </button>
                            </div>
                            <div class="col-sm-12 cursor-pointer">
                                <div class="col-sm-12 d-flex flex-row">
                                    <div class="flex-grow-1">
                                        <span class="small"> {{ utils.formatFecha(data.created_at, 'dd/MM/yyyy HH:mm:ss ') }}</span>
                                    </div>
                                    <div>
                                        <span class="text-light">
                                            <span v-if="!data.more" @click="showMore(data)">
                                                <b><i><u>Leer más</u></i></b>
                                            </span>
                                            <span v-else @click="showMore(data)">
                                                <b><i><u>Leer menos</u></i></b>
                                            </span>
                                        </span>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-light d-flex flex-row align-items-center">
                <div class="flex-grow-1">
                    <button @click="collapseNotificaciones" class="btn btn-sm btn-outline-light me-1 px-2">
                        <i class="fa fa-chevron-right"></i>
                    </button>
                </div>
                <div class="float-end"> {{ datasCount + (datasCount === 0 || datasCount > 1 ? ' Resultados' : ' Resultado')}}</div>
            </div>
        </div>
        <div v-else class="d-flex flex-column h-100">
            <div class="text-light h3 m-0 text-center">
                <i class="fa fa-bell"></i>
            </div>
            <div class="flex-grow-1">
                <div class="badge rounded-pill text-dark mt-2 py-1 px-1 w-100"
                     :class="{'bg-warning': datasCount > 0, 'bg-success': datasCount == 0}"
                     style="font-size: 1rem;">
                    <span v-if="datasCount > 99">99+</span>
                    <span v-else>{{ datasCount }}</span>
                </div>
            </div>
            <button @click="collapseNotificaciones" class="btn btn-sm btn-outline-light me-1 px-2">
                <i class="fa fa-chevron-left"></i>
            </button>
        </div>
    </div>
</template>
<script>
    import Paginador from "../../components/utils/Paginador";
    import {EventBus} from '@/event-bus';
    import Utils from "../../code/utils";
    import TipoNotificacion from "../../code/TipoNotificacion";

    export default {
        name: 'Notificaciones',
        components: {
            Paginador,
        },
        data() {
            return {
                url_base: '/api/notificaciones',
                errors: [],
                datas: {},
                filtros: {
                    user_id: '',
                },
                data: {
                    id: null,
                    more: false,
                    notification_id: null,
                    notification_type: null,
                    text: null,
                    tipo: null,
                },
                big: true,
                saving: false,
                timeInterval: null,
                datasCount: 0,
                utils: Utils,
                TipoNotificacion,
            };
        },
        mounted() {
            this.big = localStorage.getItem('collapse.notificaciones') === 'true';
            this.timeInterval = setInterval(() => {
                this.getNotificaciones();
            }, 60000);

            this.getNotificaciones();
        },
        methods: {
            getNotificaciones() {
                axios.get(this.url_base, {
                    params: {
                        campos: {
                            user_id: this.$store.state.user.data.id,
                        }
                    }
                })
                    .then(res => {
                        if (res.data.status === 'ok') {
                            this.datas = res.data.data.items.data ?? {};
                            this.datasCount = res.data.data.total;
                        }
                        this.$refreshSelectPicker();
                    });
            },
            showMore(data) {
                this.data = data;
                this.data.more = !this.data.more;
            },
            marcarVisto(data) {
                this.saving = true;
                this.data = data;
                this.data.visto = true;

                axios.put(this.url_base + '/' + this.data.id, this.data)
                    .then(res => {
                        if (res.data.status === 'ok') {
                            this.getNotificaciones();
                            EventBus.$emit('messages.show_success', res.data.data.message);
                        } else {
                            EventBus.$emit('messages.show_error', res.data.data.message);
                        }
                        this.saving = false;
                    }).catch(error => {
                    this.errors = error.response ? error.response.data.errors : [];
                });
            },
            detalleVenta(data) {
                this.$router.push("/cotizaciones/show/" + data.notification.cotizacion_id + '?random=' + Date.now());
            },
            collapseNotificaciones() {
                this.big = !this.big;
                localStorage.setItem('collapse.notificaciones', this.big);
            }
        }
    }
</script>
<style scoped="">
    .collapse-notificacion {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .big {
        min-width: 250px;
        max-width: 250px;
    }
</style>